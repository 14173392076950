import React, { useState, useEffect } from "react";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import type { DatePickerProps } from "antd";
import { DatePicker, Space, Select, Radio, Tabs, RadioChangeEvent } from "antd";
// import { Select, Space } from 'antd';
import Menu from "./Menu";
import Navbar from "./Navbar";
import EmployeeTable from "./EmployeeTable";
import EveningTasktable from "./EveningTasktable";
import EveningDashboardTable from "./EveningDashboardTable";
import axios from "axios";
import { format } from "date-fns";

type TabPosition = "morning" | "evening";

interface Employee {
  EmpID: number;
  firstName: string;
  role: string;
  dob: Date;
}

interface Task {
  EvngTaskID: number;
  projectName: string;
  phaseName: string;
  module: string;
  task: string;
  estTime: string;
  upWorkHrs: string;
  employeeID: string;
  currDate: string;
}

const EveningDashboard: React.FC = () => {
  const [mode, setMode] = useState<TabPosition>("morning");
  const [data, setData] = useState<any>([]);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [totalUpwork, setTotalUpWork] = useState<any>();
  const [totalEstHrs, setTotalEstHrs] = useState<any>();
  const [totalUpworkhrs, setTotalUpworkhrs] = useState<any>();

  const formattedDate = format(currentDate, "yyyy-MM-dd");

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const handleModeChange = (e: RadioChangeEvent) => {
    setMode(e.target.value);
  };

  useEffect(() => {
    axios
      .get<Task[]>("https://empbackend.base2brand.com/get/addTaskEvening", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
      })
      .then((response) => {
        const res = response.data.filter(
          (obj) => obj.currDate === formattedDate
        );

        const sortedData = res.sort(
          (a, b) => Number(b.EvngTaskID) - Number(a.EvngTaskID)
        );

        const result = sortedData.reduce((acc: Record<string, any>, obj) => {
          if (!acc[obj.employeeID]) {
            acc[obj.employeeID] = [];
          }
          acc[obj.employeeID].push(obj);
          return acc;
        }, {});

        setData(result);
        console.log(result,"resultresultresultresultresult");


        // sort the data array in reverse order based on ProID
        // const sortedData = response.data.sort((a, b) => Number(b.MrngTaskID) - Number(a.MrngTaskID));
        // setData(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  console.log(data,"dataresultresultresultresultresult");


  return (
    <div className="emp-main-div">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          // maxHeight:'90%'
        }}
      >
        <div style={{ height: "8%" }}>
          <Navbar />
        </div>
        <div style={{ display: "flex", flexDirection: "row", height: "90%" }}>
          <div className="menu-div">
            <Menu />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column" }}
            className="form-container"
          >
            <div
              style={{
                display: "flex",
                width: "80%",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <EveningDashboardTable
               data={data}
                totalUpwork={totalUpwork}
                setTotalUpWork={setTotalUpWork}
                totalEstHrs={totalEstHrs}
                setTotalEstHrs={setTotalEstHrs}
                totalUpworkhrs={totalUpworkhrs}
                setTotalUpworkhrs={setTotalUpworkhrs}
              />
            </div>
            <div style={{ width: "90%", height: "80%", marginTop: "3%" }}>
              <div style={{}}>
                <EveningTasktable
                  data={data}
                  totalUpwork={totalUpwork}
                  setTotalUpWork={setTotalUpWork}
                  totalEstHrs={totalEstHrs}
                  setTotalEstHrs={setTotalEstHrs}
                  totalUpworkhrs={totalUpworkhrs}
                  setTotalUpworkhrs={setTotalUpworkhrs}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EveningDashboard;
