import React, { createContext, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import "./App.css";

import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard";
import EmployeeForm from "./Components/EmployeeForm";
import EmployeeList from "./Components/EmployeeList";
import EveningDashboard from "./Components/EveningDashboard";
import AddProject from "./Components/AddProject";
import AddPhase from "./Components/AddPhase";
import AddModule from "./Components/AddModule";
import ViewProject from "./Components/ViewProject";
import ViewPhase from "./Components/ViewPhase";
import ViewModule from "./Components/ViewModule";
import TableNavbar from "./Components/TableNavbar";
import AddMorningTask from "./Components/AddMorningTask";
import AddEveningTask from "./Components/AddEveningTask";
import PhaseAssignedTo from "./Components/PhaseAssignedTo";
import ViewPhaseAssign from "./Components/ViewPhaseAssign";
import EditAddPhase from "./Components/EditAddPhase";
import AboutProject from "./Components/AboutProject";
import AssignTaskPage from "./Components/AssignTaskPage";
import ViewBacklogPage from "./Components/ViewBacklogPage";
import LeavePage from "./Components/LeavePage";
import EditAddModule from "./Components/EditAddModule";
import ShiftChangePage from "./Components/ShiftChangePage";
import HRsection from "./Components/HRsection";
import HRshiftChangeSection from "./Components/HRshiftChangeSection";
import HrLeaveAutoFill from "./Components/HrLeaveAutoFill";
import HrLeaveReport from "./Components/HrLeaveReport";
import SalecampusFormList from "./Components/SalecampusFormList";
import SaleInfoForm from "./Components/SaleInfoForm";



export const GlobalInfo = createContext<any>({});
interface ProtectedRouteProps {
  children: React.ReactNode;
}

const App: React.FC = () => {
  const [empObj, setEmpObj] = useState<any>();
  const [projEditObj, setProjEditObj] = useState<any>();
  const [phasejEditObj, setPhasejEditObj] = useState<any>();
  const [modulejEditObj, setModulejEditObj] = useState<any>();
  const info = JSON.parse(localStorage.getItem("admin") || "{}");


  const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const navigate = useNavigate();

    const token = localStorage.getItem("adminToken");
    if (!token) {
      alert("Please log in!");
      navigate("/");
      return null;
    }
    return <>{children}</>;
  };

  return (
    <Router>
      <GlobalInfo.Provider
        value={{
          empObj,
          setEmpObj,
          projEditObj,
          setProjEditObj,
          phasejEditObj,
          setPhasejEditObj,
          modulejEditObj,
          setModulejEditObj,
        }}
      >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/employee-form"
            element={
              <ProtectedRoute>
                <EmployeeForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employee-list"
            element={
              <ProtectedRoute>
                <EmployeeList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EveningDashboard"
            element={
              <ProtectedRoute>
                <EveningDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-project"
            element={
              <ProtectedRoute>
                <AddProject />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-phase"
            element={
              <ProtectedRoute>
                <AddPhase />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-module"
            element={
              <ProtectedRoute>
                <AddModule />
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-project"
            element={
              <ProtectedRoute>
                <ViewProject />
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-phase"
            element={
              <ProtectedRoute>
                <ViewPhase />
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-module"
            element={
              <ProtectedRoute>
                <ViewModule />
              </ProtectedRoute>
            }
          />
          <Route
            path="/table-navbar"
            element={
              <ProtectedRoute>
                <TableNavbar />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-morning-task"
            element={
              <ProtectedRoute>
                <AddMorningTask />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-evening-task"
            element={
              <ProtectedRoute>
                <AddEveningTask />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PhaseAssignedTo"
            element={
              <ProtectedRoute>
                <PhaseAssignedTo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ViewPhaseAssign"
            element={
              <ProtectedRoute>
                <ViewPhaseAssign />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditAddPhase"
            element={
              <ProtectedRoute>
                <EditAddPhase />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AboutProject"
            element={
              <ProtectedRoute>
                <AboutProject />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AssignTaskPage"
            element={
              <ProtectedRoute>
                <AssignTaskPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ViewBacklogPage"
            element={
              <ProtectedRoute>
                <ViewBacklogPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/LeavePage"
            element={
              <ProtectedRoute>
                <LeavePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ShiftChangePage"
            element={
              <ProtectedRoute>
                <ShiftChangePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EditAddModule"
            element={
              <ProtectedRoute>
                <EditAddModule />
              </ProtectedRoute>
            }
          />

{info?.role == "super_admin" && (
  <>
          <Route
            path="/HRsection"
            element={
              <ProtectedRoute>
                <HRsection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/HRshiftChangeSection"
            element={
              <ProtectedRoute>
                <HRshiftChangeSection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/HrLeaveAutoFill"
            element={
              <ProtectedRoute>
                <HrLeaveAutoFill />
              </ProtectedRoute>
            }
          />

          <Route
            path="/HrLeaveReport"
            element={
              <ProtectedRoute>
                <HrLeaveReport />
              </ProtectedRoute>
            }
          />

<Route
            path="/salecampusformlist"
            element={
              <ProtectedRoute>
                <SalecampusFormList />
              </ProtectedRoute>
            }
          />
          </>
)}

        </Routes>
      </GlobalInfo.Provider>
    </Router>
  );
};

export default App;
