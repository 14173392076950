import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import {
  DashboardOutlined,
  UserOutlined,
  PlusOutlined,
  TableOutlined,
  ScheduleOutlined,
  AppstoreOutlined,
  MenuOutlined
} from '@ant-design/icons';

const AppMenu = () => {

  const info  = JSON.parse(localStorage.getItem("admin") || '{}');

  return (
    <Menu mode="inline">

{info.role == "manager" && (
  <>
      {/* <Menu.Item key="menu" icon={<MenuOutlined />}> */}
        {/* <Link to="/menu">Menu</Link> */}
      {/* </Menu.Item> */}
      <Menu.Item key="dashboard" icon={<DashboardOutlined rev={undefined} />}>
        <Link to="/dashboard">Dashboard</Link>
      </Menu.Item>
      {/* <Menu.Item key="employee-form" icon={<UserOutlined rev={undefined} />}>
        <Link to="/employee-form">Employee Form</Link>
      </Menu.Item>
      <Menu.Item key="employee-list" icon={<UserOutlined rev={undefined} />}>
        <Link to="/employee-list">Employee List</Link>
      </Menu.Item> */}
      <Menu.Item key="add-project" icon={<PlusOutlined rev={undefined} />}>
        <Link to="/add-project">Add Project</Link>
      </Menu.Item>
      <Menu.Item key="add-phase" icon={<PlusOutlined rev={undefined} />}>
        <Link to="/add-phase">Add Phase</Link>
      </Menu.Item>
      <Menu.Item key="add-module" icon={<PlusOutlined rev={undefined} />}>
        <Link to="/add-module">Add Module</Link>
      </Menu.Item>
      <Menu.Item key="add-PhaseAssignedTo" icon={<PlusOutlined rev={undefined} />}>
        <Link to="/PhaseAssignedTo">Assign Phase</Link>
      </Menu.Item>
      <Menu.Item key="view-project" icon={<TableOutlined rev={undefined} />}>
        <Link to="/view-project">View Project</Link>
      </Menu.Item>
      <Menu.Item key="view-phase" icon={<TableOutlined rev={undefined} />}>
        <Link to="/view-phase">View Phase</Link>
      </Menu.Item>
      <Menu.Item key="view-module" icon={<TableOutlined rev={undefined} />}>
        <Link to="/view-module">View Module</Link>
      </Menu.Item>
      <Menu.Item key="ViewPhaseAssign" icon={<TableOutlined rev={undefined} />}>
        <Link to="/ViewPhaseAssign">View Phase AssignedTo</Link>
      </Menu.Item>
      <Menu.Item key="AboutProject" icon={<TableOutlined rev={undefined} />}>
        <Link to="/AboutProject">Projects Report</Link>
      </Menu.Item>
      <Menu.Item key="AssignTaskPage" icon={<PlusOutlined rev={undefined} />}>
        <Link to="/AssignTaskPage">Assign Task </Link>
      </Menu.Item>
      <Menu.Item key="ViewBacklogPage" icon={<TableOutlined rev={undefined} />}>
        <Link to="/ViewBacklogPage">View Backlog Page</Link>
      </Menu.Item>
      <Menu.Item key="LeavePage" icon={<TableOutlined rev={undefined} />}>
        <Link to="/LeavePage">LeavePage</Link>
      </Menu.Item>
      <Menu.Item key="ShiftChangePage" icon={<TableOutlined rev={undefined} />}>
        <Link to="/ShiftChangePage">ShiftChangePage</Link>
      </Menu.Item>


      {/* <Menu.Item key="table-navbar" icon={<TableOutlined />}>
        <Link to="/table-navbar">Table Navbar</Link>
      </Menu.Item> */}
      {/* <Menu.Item key="add-morning-task" icon={<ScheduleOutlined />}>
        <Link to="/add-morning-task">Add Morning Task</Link>
      </Menu.Item>
      <Menu.Item key="add-evening-task" icon={<ScheduleOutlined />}>
        <Link to="/add-evening-task">Add Evening Task</Link>
      </Menu.Item> */}
      </>
)}



{info.role == "super_admin" && (
<>
<Menu.Item key="dashboard" icon={<DashboardOutlined rev={undefined} />}>
        <Link to="/dashboard">Dashboard</Link>
      </Menu.Item>

       <Menu.Item key="employee-form" icon={<UserOutlined rev={undefined} />}>
        <Link to="/employee-form">Employee Form</Link>
      </Menu.Item>
      <Menu.Item key="employee-list" icon={<UserOutlined rev={undefined} />}>
        <Link to="/employee-list">Employee List</Link>
      </Menu.Item>

      <Menu.Item key="add-project" icon={<PlusOutlined rev={undefined} />}>
        <Link to="/add-project">Add Project</Link>
      </Menu.Item>
      <Menu.Item key="add-phase" icon={<PlusOutlined rev={undefined} />}>
        <Link to="/add-phase">Add Phase</Link>
      </Menu.Item>

      <Menu.Item key="add-module" icon={<PlusOutlined rev={undefined} />}>
        <Link to="/add-module">Add Module</Link>
      </Menu.Item>
      <Menu.Item key="add-PhaseAssignedTo" icon={<PlusOutlined rev={undefined} />}>
        <Link to="/PhaseAssignedTo">Assign Phase</Link>
      </Menu.Item>
      <Menu.Item key="view-project" icon={<TableOutlined rev={undefined} />}>
        <Link to="/view-project">View Project</Link>
      </Menu.Item>
      <Menu.Item key="view-phase" icon={<TableOutlined rev={undefined} />}>
        <Link to="/view-phase">View Phase</Link>
      </Menu.Item>
      <Menu.Item key="view-module" icon={<TableOutlined rev={undefined} />}>
        <Link to="/view-module">View Module</Link>
      </Menu.Item>
      <Menu.Item key="ViewPhaseAssign" icon={<TableOutlined rev={undefined} />}>
        <Link to="/ViewPhaseAssign">View Phase AssignedTo</Link>
      </Menu.Item>
      <Menu.Item key="AboutProject" icon={<TableOutlined rev={undefined} />}>
        <Link to="/AboutProject">Projects Report</Link>
      </Menu.Item>
      <Menu.Item key="AssignTaskPage" icon={<PlusOutlined rev={undefined} />}>
        <Link to="/AssignTaskPage">Assign Task </Link>
      </Menu.Item>
      <Menu.Item key="ViewBacklogPage" icon={<TableOutlined rev={undefined} />}>
        <Link to="/ViewBacklogPage">View Backlog Page</Link>
      </Menu.Item>
      <Menu.Item key="LeavePage" icon={<TableOutlined rev={undefined} />}>
        <Link to="/LeavePage">LeavePage</Link>
      </Menu.Item>
      <Menu.Item key="ShiftChangePage" icon={<TableOutlined rev={undefined} />}>
        <Link to="/ShiftChangePage">ShiftChangePage</Link>
      </Menu.Item>
      <Menu.Item key="salecampusformlist" icon={<TableOutlined />}>
         <Link to="/salecampusformlist">Sale Campus List</Link>
       </Menu.Item>

</>

)}



    </Menu>
  );
};

export default AppMenu;
