import { useState, useEffect } from 'react';
import { Input, Layout, Avatar, Badge, Popover, List } from 'antd';
import { SearchOutlined, BellOutlined, UserOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;

interface BacklogTask {
  backlogTaskID: number;
  taskName: string;
  assigneeName: string;
  assigneeEmployeeID: string;
  deadlineStart: string;
  deadlineEnd: string;
  currdate: string;
  UserEmail: string;
  AssignedBy: string;
  isCompleted: boolean;
  employeeID: string;
}

const Navbar: React.FunctionComponent = () => {
  const [notifications, setNotifications] = useState<BacklogTask[]>([]);
  const [visitedNotificationIds, setVisitedNotificationIds] = useState<number[]>(() => {
    const saved = localStorage.getItem('visitedNotificationIds');
    return saved ? JSON.parse(saved) : [];
  });

  const navigate = useNavigate();
  const storedData = localStorage.getItem('admin');
  const empInfo = storedData ? JSON.parse(storedData) : null;

  useEffect(() => {
    localStorage.setItem('visitedNotificationIds', JSON.stringify(visitedNotificationIds));
  }, [visitedNotificationIds]);

  useEffect(() => {
    const socket = io('https://empbackend.base2brand.com');
    socket.on('taskCompleted', handleTaskCompleted);
    return () => {
      socket.off('taskCompleted', handleTaskCompleted);
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

    axios
      .get('https://empbackend.base2brand.com/get/BacklogTasks')
      .then((response) => {
        const completedTasks = response.data.filter((task: BacklogTask) => {
          const taskDate = new Date(task.currdate);
          return task.isCompleted && taskDate >= threeDaysAgo;
        });
        setNotifications(completedTasks);
      })
      .catch((error) => {
        console.error('Error fetching completed tasks:', error);
      });
  }, []);

  const handleTaskCompleted = (taskId: any) => {
    axios
      .get('https://empbackend.base2brand.com/get/BacklogTasks')
      .then((response) => {
        const completedTask = response.data.find((task: BacklogTask) => task.backlogTaskID === taskId);
        if (completedTask) {
          setNotifications((prevNotifications) => [...prevNotifications, completedTask]);
        }
      })
      .catch((error) => {
        console.error('Error fetching tasks:', error);
      });
  };

  const handleLogout = () => {
    if (window.confirm('Do you really want to logout?')) {
      localStorage.removeItem('admin');
      navigate('/');
    }
  };

  const notificationList = (
    <List
      itemLayout="horizontal"
      dataSource={notifications.filter(notification => !visitedNotificationIds.includes(notification.backlogTaskID))}
      renderItem={(item) => (
        <List.Item
          key={item.backlogTaskID}
          onClick={() => {
            navigate('/ViewBacklogPage');
            if (!visitedNotificationIds.includes(item.backlogTaskID)) {
              setVisitedNotificationIds(prevState => {
                const newVisitedNotificationIds = [...prevState, item.backlogTaskID];
                localStorage.setItem('visitedNotificationIds', JSON.stringify(newVisitedNotificationIds));
                return newVisitedNotificationIds;
              });
            }
          }}
          style={{
            padding: '10px',
            backgroundColor: visitedNotificationIds.includes(item.backlogTaskID) ? '#f5f5f5' : '#ffffff',
            borderRadius: '5px',
            marginBottom: '10px',
            cursor: 'pointer',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
            width: '18vw',
          }}
        >
          <List.Item.Meta
            title={`Task completed by ${item?.assigneeName}: ${item?.taskName}`}
          />
          <CloseOutlined
            onClick={(e) => {
              e.stopPropagation(); // Prevents the parent click event from triggering
              if (!visitedNotificationIds.includes(item.backlogTaskID)) {
                setVisitedNotificationIds([...visitedNotificationIds, item.backlogTaskID]);
              }
              setNotifications((prevNotifications) =>
                prevNotifications.filter((notification) => notification.backlogTaskID !== item.backlogTaskID)
              );
            }}
          />
        </List.Item>
      )}
      style={{
        padding: '10px',
        backgroundColor: '#f5f5f5',
        borderRadius: '5px',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
        width: '20vw',
        maxHeight: '36em',
        overflow: 'auto',
      }}
    />
  );

  return (
    <Header
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'white',
      }}
      className="navbar"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '40%',
        }}
      >
        <div className="logo">
          <img src="./b2b.png" alt="Company Logo" />
        </div>
        <div className="search">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search..."
            style={{ borderRadius: '10px' }}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          width: '40%'
        }}
      >
        <div className="notifications">
          <Popover content={notificationList} trigger="click" placement="bottomRight">
            <Badge count={notifications.filter(notification => !visitedNotificationIds.includes(notification.backlogTaskID)).length}>
              <BellOutlined style={{ fontSize: '20px' }} />
            </Badge>
          </Popover>
        </div>
        <div  style={{marginLeft:'30px'}} className="avatar">
          <Avatar size={40} icon={<UserOutlined />} />
          <span  style={{marginLeft:'20px'}} className="username">{empInfo?.adminName}</span>
        </div>
        {/* <div className="logout" onClick={handleLogout}>
          Logout
        </div> */}
      </div>
    </Header>
  );
};

export default Navbar;
